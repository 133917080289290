import {ControllerProps, MemberDetailsState} from '../../../types/app.types';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {CheckoutModel} from '../../../domain/models/Checkout.model';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions/dist/types/lib/apply-overrides';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';

export type MemberDetailsDataContextType = {
  selectedAddressesServiceId?: string;
  selectedAddressesService?: AddressWithContactModel;
  setAddressServiceById: (id: string) => void;
  memberDetailsState: MemberDetailsState;
  setMemberDetailsState: (state: MemberDetailsState) => void;
  resetMemberDetailsState: () => void;
  areMemberDetailsValid: boolean;
};

export type MemberDetailsValidator<S> = (options: {
  formsById: {[key: string]: ReturnType<typeof applyRuleOverrides>};
  addressAndContact: AddressWithContactModel;
  checkoutSettings: CheckoutSettingsModel;
  context: S;
}) => Promise<boolean>;

export const MemberDetailsDataContext = createContext({} as MemberDetailsDataContextType);

/* eslint-disable sonarjs/cognitive-complexity */
export function withMemberDetailsData<T extends object, S>(
  Component: ComponentType<T>,
  getAddressAndContactFromCheckout: (checkout: CheckoutModel) => AddressWithContactModel | undefined,
  validator: MemberDetailsValidator<S>,
  getValidationContext: (controllerProps: ControllerProps) => S,
  initialState: MemberDetailsState = MemberDetailsState.COLLAPSED
) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {isAddressesAppInstalled, isMember},
    } = useControllerProps();
    const {wfConfig} = useControllerProps() as unknown as {wfConfig?: {formsById: any}};

    const {selectedAddressesServiceId, selectedAddressesService, setAddressServiceById, resetAddressService} =
      useSelectedServiceAddress(getAddressAndContactFromCheckout(checkout));

    const [memberDetailsState, setMemberDetailsState] = useState(initialState);
    const [areMemberDetailsValid, setAreMemberDetailsValid] = useState(true);

    const validationContext = getValidationContext(useControllerProps());

    useEffect(
      () => {
        if (!isMember || !isAddressesAppInstalled || !wfConfig?.formsById) {
          return;
        }
        const validateData = async (addressAndContact: AddressWithContactModel) => {
          const isValid = await validator({
            formsById: wfConfig?.formsById,
            addressAndContact,
            checkoutSettings,
            context: validationContext,
          });

          setAreMemberDetailsValid(isValid);
        };

        selectedAddressesService && void validateData(selectedAddressesService);
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [
        validationContext,
        checkoutSettings,
        wfConfig?.formsById,
        selectedAddressesServiceId,
      ]
    );

    useEffect(() => {
      if (memberDetailsState === MemberDetailsState.COLLAPSED && !areMemberDetailsValid) {
        setMemberDetailsState(MemberDetailsState.OPEN);
      }
    }, [areMemberDetailsValid, setMemberDetailsState, memberDetailsState]);

    const resetMemberDetailsState = () => {
      resetAddressService();
      setMemberDetailsState(
        areMemberDetailsValid && selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID
          ? initialState
          : MemberDetailsState.OPEN
      );
    };

    return (
      <MemberDetailsDataContext.Provider
        value={{
          selectedAddressesServiceId,
          selectedAddressesService,
          setAddressServiceById,
          memberDetailsState,
          setMemberDetailsState,
          resetMemberDetailsState,
          areMemberDetailsValid,
        }}>
        <Component {...props} />
      </MemberDetailsDataContext.Provider>
    );
  };
}

function useSelectedServiceAddress(addressAndContact?: AddressWithContactModel) {
  const {
    memberStore: {addressesInfo, isAddressesAppInstalled, isMember, defaultAddressId},
  } = useControllerProps();

  const getInitialSelectedAddressesServiceId = (): string | undefined => {
    if (!isMember || !isAddressesAppInstalled) {
      return undefined;
    }
    if (addressAndContact?.addressesServiceId) {
      return addressAndContact?.addressesServiceId;
    }
    if (!isAddressFullEnough(addressAndContact) && addressesInfo.addresses?.length && defaultAddressId) {
      return defaultAddressId;
    }
    return ADD_NEW_ADDRESS_ID;
  };

  const getMemberAddressBySelectedServiceId = (id?: string) =>
    addressesInfo.addresses?.find((address) => address.addressesServiceId === id);

  const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);
  const [selectedAddressesService, setSelectedAddressesService] = useState(
    getMemberAddressBySelectedServiceId(selectedAddressesServiceId)
  );

  const setAddressServiceById = (addressesServiceId?: string) => {
    setSelectedAddressesServiceId(addressesServiceId);
    setSelectedAddressesService(getMemberAddressBySelectedServiceId(addressesServiceId));
  };

  const resetAddressService = () => {
    setAddressServiceById(getInitialSelectedAddressesServiceId());
  };

  return {
    selectedAddressesService,
    selectedAddressesServiceId,
    setAddressServiceById,
    resetAddressService,
  };
}

export function useMemberDetailsData() {
  const {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
    areMemberDetailsValid,
  } = useContext(MemberDetailsDataContext);

  return {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
    areMemberDetailsValid,
  };
}

function isAddressFullEnough(addressAndContact?: AddressWithContactModel) {
  return Boolean(
    addressAndContact?.address?.addressLine ||
      (addressAndContact?.address?.city && addressAndContact?.address?.postalCode) ||
      addressAndContact?.address?.streetAddress?.name
  );
}
