import {Dropdown, DropdownOptionProps, DropdownTheme} from 'wix-ui-tpa';
import React, {useMemo} from 'react';
import {ILocaleKeys, useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {MemberDetailsState} from '../../../../types/app.types';
import Plus from 'wix-ui-icons-common/on-stage/Plus';
import {AddressWithContactModel} from '../../../../domain/models/AddressWithContact.model';
import {ADD_NEW_ADDRESS_ID} from '../../constants';
import {useMemberDetailsData} from '../WithMemberDetailsData';

export enum AddressDropdownDataHook {
  root = 'AddressDropdown.root',
  dropdown = 'AddressDropdown.dropdown',
}

export const AddressDropdown = ({dropdownLabel}: {dropdownLabel: string}) => {
  const {
    memberStore: {addressesInfo},
  } = useControllerProps();

  const localeKeys = useLocaleKeys();
  const {selectedAddressesServiceId, memberDetailsState, setAddressServiceById} = useMemberDetailsData();
  const onSelect = ({id}: DropdownOptionProps) => {
    setAddressServiceById(id!);
  };
  const options = useMemo(() => mapToDropdownOptions(addressesInfo.addresses, localeKeys), [addressesInfo, localeKeys]);

  return (
    <div data-hook={AddressDropdownDataHook.root}>
      <Dropdown
        data-hook={AddressDropdownDataHook.dropdown}
        label={dropdownLabel}
        initialSelectedId={selectedAddressesServiceId}
        upgrade={true}
        disabled={memberDetailsState === MemberDetailsState.EDIT || selectedAddressesServiceId === ADD_NEW_ADDRESS_ID}
        theme={DropdownTheme.Box}
        options={options}
        onChange={onSelect}
      />
    </div>
  );
};

const mapToDropdownOptions = (addresses: AddressWithContactModel[], localeKeys: ILocaleKeys) => {
  const dropdownOptions: DropdownOptionProps[] = addresses.map((address) => {
    return {
      id: address?.addressesServiceId,
      value: address.address?.streetAddress?.name
        ? `${address.address?.streetAddress?.name} ${
            address.address?.streetAddress?.number ?? /* istanbul ignore next */ ''
          }`
        : address.address?.addressLine,
      subtitle: `${address.contact?.firstName ?? /* istanbul ignore next */ ''} ${
        address.contact?.lastName ?? /* istanbul ignore next */ ''
      }`,
      isSelectable: true,
    };
  });
  dropdownOptions.push({
    isSelectable: true,
    id: ADD_NEW_ADDRESS_ID,
    value: localeKeys.checkout.member.addressDropdown.addNewAddress.cta(),
    icon: <Plus />,
  });
  return dropdownOptions;
};
