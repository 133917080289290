import React from 'react';
import {classes} from './MemberDetailsSummary.st.css';
import {ContactAndAddressSummary} from '../../ContactAndAddressSummary/ContactAndAddressSummary';
import {MemberDetailsState} from '../../../../types/app.types';
import {TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useMemberDetailsData} from '../WithMemberDetailsData';

export enum MemberDetailsSummaryDataHook {
  editButton = 'MemberDetails.editButton',
}

export const MemberDetailsSummary = () => {
  const localeKeys = useLocaleKeys();
  const {selectedAddressesService} = useMemberDetailsData();

  const {memberDetailsState, areMemberDetailsValid, setMemberDetailsState} = useMemberDetailsData();

  const shouldShowEditButton = memberDetailsState === MemberDetailsState.OPEN && areMemberDetailsValid;

  return (
    <div className={classes.container}>
      <ContactAndAddressSummary
        contact={selectedAddressesService?.contact}
        address={selectedAddressesService?.address}
      />
      {shouldShowEditButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberDetailsSummaryDataHook.editButton}
          className={classes.editTitle}
          onClick={() => setMemberDetailsState(MemberDetailsState.EDIT)}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
